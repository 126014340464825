import { useContext, useRef, useMemo } from "react";

// Third-party
import axios from "axios";
import * as Sentry from "@sentry/browser";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import {
  point,
  bearing as bearing2,
  distance,
  transformTranslate
} from "@turf/turf";

// Cesium
import { default as CesMath } from "cesium/Source/Core/Math";
import Cartesian2 from "cesium/Source/Core/Cartesian2";
import CornerType from "cesium/Source/Core/CornerType";
import ClippingPlane from "cesium/Source/Scene/ClippingPlane";
import Rectangle from "cesium/Source/Core/Rectangle";
import ColorBlendMode from "cesium/Source/Scene/ColorBlendMode";
// import ClippingPlaneCollection from "cesium/Source/Scene/ClippingPlaneCollection";

import { sampleTerrainMostDetailed } from "cesium";
import {
  Color,
  HeightReference,
  Cartesian3,
  HeadingPitchRoll,
  Transforms,
  CesiumTerrainProvider,
  DistanceDisplayCondition,
  Cartographic
} from "cesium/Build/Cesium/Cesium";
import CallbackProperty from "cesium/Source/DataSources/CallbackProperty";

// Context
import { Map3DContext } from "Map3DProvider";
import { useLocalSettingsContext } from "context/LocalSettingsContext";

// Utils
import {
  computeCatenary,
  reindexEntities
} from "components/Dashboard3D/CesiumPath";
import {
  toggleEntityVisiblityByProperty,
  getColorByString,
  degreesToRadians,
  getCesiumElevatonPoints,
  getDatasourceLayerByName
} from "components/Dashboard3D/CesiumUtils";
import {
  getNode,
  getPathSections,
  getPathTechnologies
} from "utils/PathWrapper";
import { generateEquidistantArray } from "utils/ArrayTools";

// Services
import { fetchTechnology } from "services/catalog";

// Constants & Other
import {
  optimalPathVectorName,
  towerName,
  lineName
} from "components/Dashboard3D/CesiumConstants";
import PolylineDashMaterialProperty from "cesium/Source/DataSources/PolylineDashMaterialProperty";

const useCesiumPath = availableTechnologies => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const defaultColor = "#204585";
  const YELLOW = Color.YELLOW;
  const WHITE = Color.WHITE;
  const translucentWHITE = Color.fromAlpha(WHITE, 0.005);
  const CLAMP_TO_GROUND = HeightReference.CLAMP_TO_GROUND;

  const { localSettingsState } = useLocalSettingsContext();
  const map3DContext = useContext(Map3DContext);

  const isEllipsePylon = useRef(false);
  const serials = useRef([]);
  const deleteSerials = useRef(undefined);

  const viewer = useMemo(
    () => map3DContext?.state?.cesiumViewer,
    [map3DContext?.state?.cesiumViewer]
  );

  // UTILS METHODS

  //
  /**
   * Function to interpolate points between given positions.
   * It can be used to add points to an earth cable, so it
   * will better follow the terrain.
   *
   * @param {array} points
   * @param {number} numInterpolatedPoints
   *
   * @returns {array} The array containing the interpolated points.
   */
  function interpolatePoints(points, numInterpolatedPoints) {
    const interpolatedPoints = [];
    for (let i = 0; i < points.length - 1; i++) {
      const start = points[i];
      const end = points[i + 1];
      interpolatedPoints.push(start);

      for (let j = 1; j <= numInterpolatedPoints; j++) {
        const fraction = j / (numInterpolatedPoints + 1);
        const lon = start[0] + (end[0] - start[0]) * fraction;
        const lat = start[1] + (end[1] - start[1]) * fraction;
        interpolatedPoints.push([lon, lat]);
      }
    }
    interpolatedPoints.push(points[points.length - 1]);

    return interpolatedPoints;
  }

  /**
   * Method to render a circle. It can be used to
   * render a solid tunnel for instance.
   *
   * @param {number} radius The radius of the circle.
   *
   * @returns {array} Positions of the circle
   */
  function computeCircle(radius) {
    const positions = [];
    for (let i = 0; i < 360; i++) {
      const radians = CesMath.toRadians(i);
      positions.push(
        new Cartesian2(radius * Math.cos(radians), radius * Math.sin(radians))
      );
    }
    return positions;
  }

  /**
   * Method to render a hollow circle. It can be used to
   * render a hollow tunnel for instance.
   *
   * @param {number} outerRadius The outer radius of the circle.
   * @param {number} innerRadius The inner radius of the circle.
   *
   * @returns {array} Positions of the hollow circle
   */
  function computeHollowCircle(outerRadius, innerRadius) {
    const outerPositions = [];
    const innerPositions = [];

    for (let i = 0; i < 360; i++) {
      const radians = CesMath.toRadians(i);
      outerPositions.push(
        new Cartesian2(
          outerRadius * Math.cos(radians),
          outerRadius * Math.sin(radians)
        )
      );
      innerPositions.push(
        new Cartesian2(
          innerRadius * Math.cos(radians),
          innerRadius * Math.sin(radians)
        )
      );
    }

    // Add the first point again at the end to close the loop
    outerPositions.push(outerPositions[0]);
    innerPositions.push(innerPositions[0]);

    // The outer circle is counterclockwise, the inner circle needs to be clockwise
    return outerPositions.concat(innerPositions.reverse());
  }

  /**
   * This is an auxiliary function for the clipping planes. This function
   * may involve sampling the terrain and creating the normal,
   * which can be complex and require CesiumJS's terrain provider methods.
   * If the terrain is flat, `Cartesian3.UNIT_Z` is sufficient in most cases.
   *
   * @param {*} position
   * @param {*} terrainProvider
   * @returns
   */
  function getNormalAtPosition(position, terrainProvider) {
    return Cartesian3.UNIT_Z;
  }

  /**
   * Auxiliary method to calculate the clipping planes when rendering
   * tunnels clipped by terrain. (Not used yet).
   *
   * @param {array} positions Array of postions (tunnel).
   * @param {number} tunnelDepth How deep is the tunnel.
   *
   * @returns
   */
  function createClippingPlanes(positions, tunnelDepth) {
    const clippingPlanes = [];
    for (let i = 0; i < positions.length - 1; i++) {
      const start = positions[i];
      const end = positions[i + 1];
      const direction = Cartesian3.subtract(end, start, new Cartesian3());
      Cartesian3.normalize(direction, direction);
      const midpoint = Cartesian3.midpoint(start, end, new Cartesian3());

      let up = Cartesian3.UNIT_Z;

      const hasTerrain =
        viewer.terrainProvider instanceof CesiumTerrainProvider;

      if (hasTerrain) {
        up = getNormalAtPosition(midpoint, viewer.terrainProvider);
      }

      const right = Cartesian3.cross(direction, up, new Cartesian3());
      Cartesian3.normalize(right, right);
      clippingPlanes.push(new ClippingPlane(right, tunnelDepth));
    }
    return clippingPlanes;
  }

  /**
   * Method to obtain the orientation of an objext in a given
   * `position` facing from point A to point B.
   *
   * @param {array} pointA Point from where we face to pointB.
   * @param {array} pointB Point where we face from pointA.
   * @param {object} position Position of the object to set the orientation.
   *
   * @returns {object} Quaternion (x, y, z, w) representing the orientation.
   */
  function getOrientation(pointA, pointB, position) {
    const from = point(pointA);
    const to = point(pointB);

    const bearing = bearing2(from, to);
    const heading = degreesToRadians(bearing);
    const hpr = new HeadingPitchRoll(heading);

    // Ensure the position is a Cartesian3 object.
    // If it's not, convert it using Cartesian3.fromDegrees
    const cartPosition = Cartesian3.fromDegrees(
      position[0],
      position[1],
      position[2]
    );

    const orientation = Transforms.headingPitchRollQuaternion(
      cartPosition,
      hpr
    );

    return orientation;
  }

  /**
   * Calculate the geographical bounds of a rectangle.
   *
   * @param {number} centerLon Longitude of the center point.
   * @param {number} centerLat Latitude of the center point.
   * @param {number} widthMeters Width of the rectangle in meters.
   * @param {number} heightMeters Height of the rectangle in meters.
   *
   * @returns {object} Bounds of the rectangle in degrees.
   */
  function calculateRectangleBounds(
    centerLon,
    centerLat,
    widthMeters,
    heightMeters
  ) {
    const halfWidthDegrees = widthMeters / 2 / 111320; // approx. degrees per meter at equator
    const halfHeightDegrees = heightMeters / 2 / 110574; // approx. degrees per meter of latitude

    // Calculate bounds
    const west = centerLon - halfWidthDegrees;
    const east = centerLon + halfWidthDegrees;
    const south = centerLat - halfHeightDegrees;
    const north = centerLat + halfHeightDegrees;

    return { west, south, east, north };
  }

  // RENDER SECTION METHODS

  /**
   * Method to render a pylon 3D model (GLB).
   *
   * @param {number} serial The tower index that represent the pylon order.
   * @param {number} bearing Tower bearing.
   * @param {object} model Model object.
   * @param {array} coordinates Model position.
   * @param {number} id Path id.
   */
  function renderPylonModel(serial, bearing, model, coordinates, id) {
    let position = Cartesian3.fromRadians(
      coordinates.longitude,
      coordinates.latitude,
      coordinates.height
    );

    let heading = degreesToRadians(bearing);

    let hpr = new HeadingPitchRoll(heading);
    let orientation = Transforms.headingPitchRollQuaternion(position, hpr);
    const pathSource = getDatasourceLayerByName(viewer, optimalPathVectorName);
    let optimalPathVectorEntities = pathSource.entities;
    let internalShow = map3DContext.state.JSONdata[id].internalShow;
    let hasTerrain = viewer.terrainProvider instanceof CesiumTerrainProvider;

    //When we delete a pylon we have to reset the indexes of the pylons and move down one position.
    if (serial > deleteSerials.current) {
      serial = serial - 1;
      reindexEntities(viewer, optimalPathVectorName, deleteSerials.current);
    }

    // Add 3d object
    optimalPathVectorEntities.add({
      group: id,
      name: towerName,
      show: internalShow,
      data: {
        wires: model.metadata.wires,
        volt: model.metadata.volt,
        alt: coordinates.height,
        height: model.metadata.height,
        serial: serial
      },
      position: position,
      orientation: orientation,
      model: {
        uri: model.glb_file,
        color: Color.LIGHTGREY,
        allowPicking: true,
        show: localSettingsState.localSettings.showPylons, // Use state value to prevent show pylons when the user edit path with pylons hidden
        distanceDisplayCondition: new DistanceDisplayCondition(1.0, 2000.0),
        heightReference: hasTerrain ? HeightReference.NONE : CLAMP_TO_GROUND
      }, //when editing we keep those visible from everywhere since you might want to edit at a bird's eye view
      cylinder: {
        length: 90,
        topRadius: 12.0,
        bottomRadius: 12.0,
        material: translucentWHITE,
        outlineWidth: 0
      },
      ellipse: {
        heightReference: HeightReference.RELATIVE_TO_GROUND,
        semiMinorAxis: 20,
        semiMajorAxis: 20,
        height: 2,
        extrudedHeight: 2,
        outline: true,
        outlineColor: YELLOW,
        outlineWidth: 5,
        show: isEllipsePylon.current,
        material: translucentWHITE
      }
    });
    viewer.scene.requestRender();
  }

  /**
   * Method to render an spherical connector of a given size and
   * in a given depth.
   *
   * @param {object} position Position of the sphere.
   * @param {number} size Radius of the sphere.
   * @param {number} depth Depth where the sphere will sit.
   * @param {string} color Color of the sphere.
   */
  async function drawSphereConnector(
    position,
    size = 10,
    depth = 15,
    color = "rgba(255,0,0,1)"
  ) {
    try {
      const { longitude, latitude, height } = position;
      const center = Cartesian3.fromRadians(
        longitude,
        latitude,
        height - depth
      );

      const pathSource = getDatasourceLayerByName(
        viewer,
        optimalPathVectorName
      );

      if (!pathSource) {
        throw new Error(
          `DataSource layer named ${optimalPathVectorName} not found`
        );
      }

      let optimalPathVectorEntities = pathSource.entities;

      // You can comment this out if you encounter issues with it
      optimalPathVectorEntities.suspendEvents();
      const radius = size;
      let material = color;
      if (color !== Color.BLACK) material = getColorByString(color);
      // Create a box entity
      optimalPathVectorEntities.add({
        name: "Connector",
        position: center,
        ellipsoid: {
          radii: new Cartesian3(radius, radius, radius),
          material: material
        }
      });

      optimalPathVectorEntities.resumeEvents(); // Resume events if it was suspended previously

      viewer.scene.requestRender();
    } catch (error) {
      console.error("Error in drawCubeConnector:", error);
    }
  }

  /**
   * Method to render a connector when a section path changes its
   * technology (same type).
   *
   * @param {object} connector Node where the change of technology takes place.
   * @param {string} color Path color.
   */
  async function createConnector(connector, color) {
    try {
      const carto = Cartographic.fromDegrees(
        connector.coordinates[0],
        connector.coordinates[1],
        new Cartographic()
      );

      let elevationPoints = await getCesiumElevatonPoints(
        [carto],
        map3DContext.state.terrainAsset
      );

      if (!elevationPoints || elevationPoints.length === 0) {
        throw new Error("No elevation data found for provided coordinates");
      }

      drawSphereConnector(elevationPoints[0], 2, 4, color);
    } catch (error) {
      console.error("Error in createConnector:", error);
    }
  }

  /**
   * Method to render a basic 3D model representing the transition
   * between two sections of the path, that's where the technology
   * type changes (O, U or T).
   *
   * @param {number} pathId Id of the path.
   * @param {object} node Data for the node where the transition takes place.
   * @param {object} nextNode Data for the next node in the transition.
   * @param {object} nodeElevation Terrain elevation at the point where the node is located.
   * @param {number} offset Offset distance to ground for the model.
   * @param {string} color Transition color.
   */
  function drawBasicTransitionModel(
    pathId,
    node,
    nextNode,
    nodeElevation,
    offset = 20,
    color
  ) {
    try {
      const modelURL = "/models/SimpleCube.glb";

      // Find the center to place the model.
      const { longitude, latitude, height } = nodeElevation;
      const center = Cartesian3.fromRadians(
        longitude,
        latitude,
        height - offset
      );

      // Get the path source.
      const pathSource = getDatasourceLayerByName(
        viewer,
        optimalPathVectorName
      );
      if (!pathSource) {
        throw new Error(
          `DataSource layer named ${optimalPathVectorName} not found`
        );
      }

      // Get the path entities.
      let optimalPathVectorEntities = pathSource.entities;
      optimalPathVectorEntities.suspendEvents();

      const orientation = getOrientation(
        node?.coordinates,
        nextNode.coordinates,
        center
      );

      // The default color is gray
      let modelColor = color ? Color.fromCssColorString(color) : Color.GRAY;

      // Place the model in the paths source.
      optimalPathVectorEntities.add({
        group: pathId,
        name: "Connector",
        position: center,
        model: {
          uri: modelURL,
          scale: 0.1,
          minimumPixelSize: 1.0,
          maximumScale: 20000,
          color: modelColor,
          colorBlendMode: ColorBlendMode.MIX, // You can choose from MIX, REPLACE, HIGHLIGHT
          orientation: orientation
        }
      });

      // Resume events if it was suspended previously
      optimalPathVectorEntities.resumeEvents();
      viewer.scene.requestRender();
    } catch (error) {
      console.error("Error in drawBasicTransitionModel:", error);
    }
  }

  /**
   * Method to render a rectangle on the ground representing a transition
   * between two technology types (O, U, T).
   *
   * @param {number} pathId Id of the path.
   * @param {object} node Data for the node where the transition takes place.
   * @param {object} nextNode Data for the next node in the transition.
   * @param {number} width Width of the rectangle representing the transition area.
   * @param {number} height Height of the rectangle representing the transition area.
   * @param {string} color Circle color, defaults to GRAY.
   */
  function drawRectangleTransitionArea(
    pathId,
    node,
    nextNode,
    width,
    height,
    color
  ) {
    try {
      const { west, south, east, north } = calculateRectangleBounds(
        node.coordinates[0],
        node.coordinates[1],
        width,
        height
      );

      const start = point([node.coordinates[0], node.coordinates[1]]);
      const end = point([nextNode.coordinates[0], nextNode.coordinates[1]]);

      // Calculate the bearing angle
      const bearing = bearing2(start, end) + 180;

      // Convert bearing to radians for Cesium's rotation
      const angleInRadians = CesMath.toRadians(bearing);

      // Get the path source.
      const pathSource = getDatasourceLayerByName(
        viewer,
        optimalPathVectorName
      );
      if (!pathSource) {
        throw new Error(
          `DataSource layer named ${optimalPathVectorName} not found`
        );
      }

      // Get the path entities.
      let optimalPathVectorEntities = pathSource.entities;
      optimalPathVectorEntities.suspendEvents();

      // Set the color if the parameter exists, set it to GRAY otherwise.
      let material;
      if (color) {
        material = getColorByString(color, 0.3);
      } else {
        material = Color.GRAY.withAlpha(0.3);
      }

      // Place the model in the paths source.
      optimalPathVectorEntities.add({
        group: pathId,
        name: "Rectangle",
        rectangle: {
          coordinates: Rectangle.fromDegrees(west, south, east, north),
          material: material,
          rotation: angleInRadians,
          stRotation: angleInRadians,
          heightReference: HeightReference.CLAMP_TO_GROUND
        },
        position: Cartesian3.fromDegrees(
          node.coordinates[0],
          node.coordinates[1]
        ),
        orientation: Transforms.headingPitchRollQuaternion(
          Cartesian3.fromDegrees(node.coordinates[0], node.coordinates[1]),
          new HeadingPitchRoll(angleInRadians, 0, 0)
        )
      });

      // Resume events if it was suspended previously
      optimalPathVectorEntities.resumeEvents();
      viewer.scene.requestRender();
    } catch (error) {
      console.error("Error in drawBasicTransitionModel:", error);
    }
  }

  /**
   * Method to render a circle on the ground representing a transition
   * between two technology types (O, U, T).
   *
   * @param {number} pathId Id of the path.
   * @param {object} node Data for the node where the transition takes place.
   * @param {object} nextNode Data for the next node in the transition.
   * @param {number} radius Radius of the circle representing the transition area.
   * @param {string} color Circle color, defaults to GRAY.
   */
  function drawCircleTransitionArea(
    pathId,
    node,
    nextNode,
    radius,
    color = null
  ) {
    try {
      // Get the path source.
      const pathSource = getDatasourceLayerByName(
        viewer,
        optimalPathVectorName
      );
      if (!pathSource) {
        throw new Error(
          `DataSource layer named ${optimalPathVectorName} not found`
        );
      }

      // Get the path entities.
      let optimalPathVectorEntities = pathSource.entities;
      optimalPathVectorEntities.suspendEvents();

      // Set the color if the parameter exists, set it to GRAY otherwise.
      let material;
      if (color) {
        material = getColorByString(color, 0.3);
      } else {
        material = Color.GRAY.withAlpha(0.3);
      }

      optimalPathVectorEntities.add({
        group: pathId,
        name: "transitionCircle",
        position: Cartesian3.fromDegrees(
          node.coordinates[0],
          node.coordinates[1]
        ),
        ellipse: {
          semiMajorAxis: radius,
          semiMinorAxis: radius,
          material: material,
          heightReference: HeightReference.CLAMP_TO_GROUND
        }
      });

      // Resume events if it was suspended previously
      optimalPathVectorEntities.resumeEvents();
      viewer.scene.requestRender();
    } catch (error) {
      console.error("Error in drawBasicTransitionModel:", error);
    }
  }

  /**
   * Method to render the transition between two sections of the path,
   * that's where the technology type changes (O, U or T).
   *
   * @param {number} pathId  Id of the path.
   * @param {object} node Data for the node where the transition takes place.
   * @param {object} nextNode Data for the next node in the transition.
   * @param {object} nodeElevation Terrain elevation at the point where the node is located.
   * @param {string} color Transition color.
   * @param {number} offset Offset distance to ground for the model.
   */
  function createTransition(
    pathId,
    node,
    nextNode,
    nodeElevation,
    color,
    offset
  ) {
    try {
      // Draw a circle shape transition area in the ground.
      drawCircleTransitionArea(pathId, node, nextNode, 15);

      // Draw a rectanle shape transition area in the ground.
      // drawRectangleTransitionArea(pathId, node, nextNode, 30, 30);

      // Render a basic transition model in 3D.
      // drawBasicTransitionModel(pathId, node, nextNode, nodeElevation, offset);
    } catch (error) {
      console.error("Error in createTransition:", error);
    }
  }

  /**
   * Method to render an earth cable (underground) section.
   *
   * @param {number} pathId Id of the path (tunnel) to render.
   * @param {array} points Array of points for the path section.
   * @param {string} color Path (tunnel) color.
   */
  async function createEarthCable(pathId, points, color) {
    const cableDepth = 5;

    // Set if has terrain.
    const hasTerrain = viewer.terrainProvider instanceof CesiumTerrainProvider;
    let CesiumPositions = [];

    // Interpolate additional points
    const numInterpolatedPoints = 20;
    const interpolatedPoints = interpolatePoints(points, numInterpolatedPoints);

    let cartographicPoints = interpolatedPoints.map(point =>
      Cartographic.fromDegrees(point[0], point[1])
    );

    if (!hasTerrain) {
      CesiumPositions = cartographicPoints.map(point => {
        return Cartesian3.fromRadians(
          point.longitude,
          point.latitude,
          -cableDepth
        );
      });
    } else {
      // Sample terrain heights for the interpolated path positions
      let elevationPoints = await sampleTerrainMostDetailed(
        viewer.terrainProvider,
        cartographicPoints
      );

      CesiumPositions = elevationPoints.map(point => {
        return Cartesian3.fromRadians(
          point.longitude,
          point.latitude,
          point.height - cableDepth
        );
      });
    }

    // Retrieve data source for paths.
    const pathSource = getDatasourceLayerByName(viewer, optimalPathVectorName);
    let optimalPathVectorEntities = pathSource.entities;
    optimalPathVectorEntities.suspendEvents();

    // Set the properties to build the trench shape.
    let material = getColorByString(color, 0.9);
    const width = 2; // Half the total width
    const height = 2; // Half the total height
    const bottomLeft = new Cartesian2(-width / 2, -height);
    const bottomRight = new Cartesian2(width / 2, -height);
    const topRight = new Cartesian2(width / 2, 0);
    const topLeft = new Cartesian2(-width / 2, 0);

    const shape = [bottomLeft, bottomRight, topRight, topLeft];

    optimalPathVectorEntities.add({
      group: pathId,
      name: "earthCable",
      polylineVolume: {
        positions: CesiumPositions,
        shape,
        material
      },
      polyline: {
        positions: CesiumPositions,
        width: 2,
        material,
        distanceDisplayCondition: new CallbackProperty(function () {
          const undergroundEnabled = viewer.scene.globe.translucency.enabled;
          return new DistanceDisplayCondition(
            undergroundEnabled ? 0 : 10000000,
            10000000
          );
        }, false)
      }
    });
    // Resume Events
    optimalPathVectorEntities.resumeEvents();

    // Refresh Map
    viewer.scene.requestRender();
  }

  /**
   * Method to render a tunnel section.
   *
   * @param {number} pathId Id of the path (tunnel) to render.
   * @param {array} points Array of points for the path section.
   * @param {array} elevationPoints Elevation points for the section.
   * @param {string} color Path (tunnel) color.
   */
  async function createTunnel(pathId, points, elevationPoints, color) {
    const tunnelDepth = 10;

    // Set if has terrain.
    const hasTerrain = viewer.terrainProvider instanceof CesiumTerrainProvider;

    let cartographicPoints = [];
    const l = points.length - 1;

    for (let i = 0; i <= l; i++) {
      cartographicPoints.push(
        Cartographic.fromDegrees(...points[i], new Cartographic())
      );
    }

    const pathSource = getDatasourceLayerByName(viewer, optimalPathVectorName);

    let optimalPathVectorEntities = pathSource.entities;
    optimalPathVectorEntities.suspendEvents();

    for (let index = 0; index < l; index++) {
      let heightPosition = [];
      elevationPoints.forEach(position => {
        heightPosition.push(
          Cartesian3.fromRadians(
            position.longitude,
            position.latitude,
            hasTerrain ? position.height : -tunnelDepth
          )
        );
      });

      const currentPositions = heightPosition;
      const material = getColorByString(color, 0.9);

      // const clippingPlanes = createClippingPlanes(
      //   currentPositions,
      //   tunnelDepth
      // );

      // const clippingPlaneCollection = new ClippingPlaneCollection({
      //   planes: clippingPlanes,
      //   edgeWidth: 0.0
      // });

      optimalPathVectorEntities.add({
        group: pathId,
        name: "tunnel",
        polylineVolume: {
          positions: currentPositions,
          shape: computeHollowCircle(10.0, 8.0),
          cornerType: CornerType.BEVELED,
          material: material
          // clippingPlanes: clippingPlaneCollection
        },
        polyline: {
          positions: currentPositions,
          width: 2,
          material: new PolylineDashMaterialProperty({
            color: material,
            gapColor: Color.TRANSPARENT,
            dashLength: 8.0,
            dashPattern: 255 // Use different patterns (binary) for different dash styles
          }),
          // clampToGround: true,
          distanceDisplayCondition: new CallbackProperty(function () {
            const undergroundEnabled = viewer.scene.globe.translucency.enabled;
            return new DistanceDisplayCondition(
              undergroundEnabled ? 0 : 10000000,
              10000000
            );
          }, false)
        }
      });
    }

    // Resume Events
    optimalPathVectorEntities.resumeEvents();

    // Refresh Map
    viewer.scene.requestRender();
  }

  /**
   * Method to render a wire for the path. It iterates the points for the section
   * and renders the wire.
   * If the index of the wire is 0 (first one) it also renders the pylon.
   * To render the wire it calculates the catenary.
   * If there's no terrain it updates the elevations to 0.
   * If showPylons is false, it draws a single line clamped to ground.
   *
   * @param {string} pathColor Color of the path.
   * @param {number} pathId Id of the path.
   * @param {number} wireIndex The index of the wire being rendered.
   * @param {array} wirePoints The points of the wire.
   * @param {array} pathBearings The bearings for each point of the wire.
   * @param {array} pathElevationPoints The elevation points for the wire.
   * @param {number} crossarmConnectorElevation The connector height to the ground.
   * @param {object} pylonModel  The 3D model of the pylon for the section.
   * @param {array} pathMetadata  Path metadata corresponding to the current section.
   * @param {boolean} forcePylonRedraw If true we force the path pylon model to redraw.
   *
   */
  async function renderWire(
    pathColor,
    pathId,
    wireIndex,
    wirePoints,
    pathBearings,
    pathElevationPoints,
    crossarmConnectorElevation,
    pylonModel,
    pathMetadata,
    forcePylonRedraw
  ) {
    let cartographic = [];
    let line = [];
    let cableErrosList = [];
    let index;
    let towerBearing;

    let l = wirePoints.length - 1;

    // Get the path source and entities.
    const pathSource = getDatasourceLayerByName(viewer, optimalPathVectorName);
    let optimalPathVectorEntities = pathSource.entities;
    optimalPathVectorEntities.suspendEvents();

    // Set if has terrain.
    let hasTerrain = viewer.terrainProvider instanceof CesiumTerrainProvider;

    // Set inital material.
    let material = getColorByString(pathColor);

    // Iterate pathMetadata.
    let mdata = pathMetadata["root"];
    let hasMetadata = mdata && Array.isArray(mdata) && mdata.length > 0;

    for (index = 0; index < l; index++) {
      // Set from/to for the segment.
      let from = point(wirePoints[index]);
      let to = point(wirePoints[index + 1]);

      // drawSphereConnector(pathElevationPoints[index]);

      // Pylon position.
      let modelPosition = pathElevationPoints[index];
      let modelNextPosition = pathElevationPoints[index + 1];

      if (
        (map3DContext.state.needTowerRedraw || forcePylonRedraw) &&
        wireIndex === 0 &&
        index > 0
      ) {
        towerBearing = pathBearings[index] + 90;
        let v = index;
        if (isEllipsePylon.current) {
          v = serials.current[index][0];
        }
        renderPylonModel(v, towerBearing, pylonModel, modelPosition, pathId);
      }

      // Calculate bearing angle.
      let bearing = bearing2(from, to);

      // Line length kilometers.
      let distanceVar = distance(from, to);

      // Calculate caternay
      let PointElevation;
      let PointProfileElevation;
      let position = [];
      let constantHeightPosition = [];

      // Value used withou pylons, Show line clampbed
      let constantHeight = 0.0;

      // We create a point every certain distance, until we reach the next pylonModel.
      let numSegments = 25;

      let arcElevations;

      if (index === 0) {
        arcElevations = generateEquidistantArray(
          crossarmConnectorElevation,
          0,
          numSegments * 2
        );
      } else if (index === l - 1) {
        arcElevations = generateEquidistantArray(
          crossarmConnectorElevation,
          0,
          numSegments * 2
        ).reverse();
      } else {
        arcElevations = computeCatenary(
          pylonModel || null,
          distanceVar,
          numSegments
        );
      }
      // Catenaries double the number of segments.
      numSegments = arcElevations.length;

      let fixProfileElevation = modelNextPosition.height - modelPosition.height;
      let fixProfileElevationStep = fixProfileElevation / (numSegments - 1);

      // Cable geometry loop done for each inter-pylon space
      for (let q = 0; q < numSegments; q++) {
        //split line and add crossarmConnectorElevation

        if (hasTerrain) {
          PointProfileElevation =
            crossarmConnectorElevation -
            arcElevations[q] +
            modelPosition.height;

          PointProfileElevation += fixProfileElevationStep * q;

          PointElevation = PointProfileElevation;
        } else {
          PointElevation = crossarmConnectorElevation - arcElevations[q];
        }

        // Distance in kilometers.
        let point = transformTranslate(
          from,
          (distanceVar / (numSegments - 1)) * q,
          bearing
        );

        // Create Cartesian3 array
        let coordinates = point.geometry.coordinates;
        let cartoProfile = Cartographic.fromDegrees(
          ...coordinates,
          PointProfileElevation
        );

        // Line is used to calculate the cable error,
        // contain the terrain profile for each wire.
        line.push(cartoProfile);

        cartographic.push(
          Cartographic.fromDegrees(...coordinates, PointProfileElevation)
        );

        // Line used with pylons.
        position.push(Cartesian3.fromDegrees(...coordinates, PointElevation));

        // Line used without pylons.
        constantHeightPosition.push(
          Cartesian3.fromDegrees(...coordinates, constantHeight)
        );
      }

      // Draw wires (each line in separate entity).
      // We need save the internal positions and constant height positions
      // to avoid recalculate sagitta etc when the use show/hide pylons.
      let showPylons = localSettingsState.localSettings.showPylons;
      let currentPositions = showPylons ? position : constantHeightPosition;
      let internalShow = map3DContext.state.JSONdata[pathId].internalShow;

      let serial = isEllipsePylon.current ? serials.current[index][0] : index;

      // Add wire entitie
      optimalPathVectorEntities.add({
        group: pathId,
        wireIndex: wireIndex,
        show: internalShow,
        serial: serial,
        name: lineName,
        internalPosition: position,
        constantHeightPosition: constantHeightPosition,
        polyline: {
          positions: currentPositions,
          clampToGround: !showPylons,
          width: 2,
          material: material
        }
      });
      // Add positions to calculate wire errors.
      cableErrosList.push(...position);
      // Reset variables.
      position = [];
      constantHeightPosition = [];
    }

    // Render last pylon.
    // let modelPosition = pathElevationPoints[index];
    // if (
    //   (map3DContext.state.needTowerRedraw || forcePylonRedraw) &&
    //   wireIndex === 0
    // ) {
    //   towerBearing = pathBearings[index] + 90;
    //   let v = index;
    //   if (isEllipsePylon.current) {
    //     v = serials.current[index][0];
    //   }
    //   renderPylonModel(v, towerBearing, pylonModel, modelPosition, pathId);
    // }

    // We add the information to calculate the errors
    let cableErrors = {
      cartographic: cartographic,
      wireIndex: wireIndex,
      line: line,
      position: cableErrosList,
      metadata: pylonModel.metadata
    };
    try {
      map3DContext.optimalPathHighlight(pathId, cableErrors);
    } catch {
      return;
    }

    // Resume events.
    optimalPathVectorEntities.resumeEvents();

    // Refresh map.
    viewer.scene.requestRender();
  }

  /**
   * Function that calculates the points for a wire, considering the bearing between
   * pylons, and the elevation.
   *
   * @param {array} pathPoints Points (x,y) forming the path section.
   * @param {array} pathMetadata Path metadata corresponding to the current section.
   * @param {object} pylonModel The 3D model of the pylon for the section.
   * @param {number} crossArmConnectorIndex The index of the connector where the wire belongs.
   * @param {number} crossArmConnectorShift The distance from the center of the pylon to the connector in the arm.
   * @param {number} crossarmConnectorElevation The elevation from the base of the pylon to the connector in the arm.
   * @param {string} pathColor Color of the path.
   * @param {number} pathId Id of the path.
   * @param {boolean} forcePylonRedraw If true we force the path pylon model to redraw.
   */
  async function calculateWire(
    pathPoints,
    pathMetadata,
    pathElevationPoints,
    pylonModel,
    crossArmConnectorIndex,
    crossArmConnectorShift,
    crossarmConnectorElevation,
    pathColor,
    pathId,
    forcePylonRedraw
  ) {
    let wirePoints = [];
    let pathBearings = [];

    // The distance from the center of the pylon to the conector
    // in the crossarm.
    const absShift = Math.abs(crossArmConnectorShift);

    let from;
    let to;
    let bearing;
    let direction;
    let wirePoint;

    // Loop through path points.
    let i;
    let len = pathPoints.length;
    for (i = 0; i < len; i++) {
      // Get start point.
      from = point(pathPoints[i]);

      // Get next point.
      if (i === len - 1) {
        // If it's the last, let's compare with the penultimate.
        to = point(pathPoints[i - 1]);

        // Calculate the angle from the last pylon to the pylon before.
        bearing = bearing2(from, to);

        // Calculate the direction from the center to the connector.
        // to the crossarm connector.
        direction = (crossArmConnectorShift < 0 ? 270 : 90) + bearing;
      } else {
        // Get the point for the next pylon.
        to = point(pathPoints[i + 1]);

        // Calculate the angle from the current pylon to the next one.
        bearing = bearing2(from, to);

        // Calculate the direction from the center to the connector.
        // to the crossarm connector.
        direction = (crossArmConnectorShift < 0 ? 90 : 270) + bearing;
      }

      // Store bearing to use later to position the pylons.
      pathBearings.push(bearing);

      // Transformation to move the point from the center of
      // the pylon to the crossarm connector.
      wirePoint = transformTranslate(from, absShift, direction);

      // Store the calculated point in the array for the wire.
      wirePoints[i] = wirePoint.geometry.coordinates;
    }

    try {
      // Call the function to finally render the wire.
      await renderWire(
        pathColor,
        pathId,
        crossArmConnectorIndex,
        wirePoints,
        pathBearings,
        pathElevationPoints,
        crossarmConnectorElevation,
        pylonModel,
        pathMetadata,
        forcePylonRedraw
      );
    } catch (err) {
      console.log("Error calculating path elevations.", err);
    }
  }

  /**
   * Method that iterates the connectors of the pylon model and calls
   * the function to calculate the corresponding wire.
   *
   * @param {array} pathPoints Points (x,y) forming the path.
   * @param {array} pathElevationPoints Elevation points for the section.
   * @param {string} pathColor Color of the path.
   * @param {number} pathId Id of the path.
   * @param {array} pathMetadata Path metadata corresponding to the current section.
   * @param {object} pylonModel The 3D model of the pylon for the section.
   * @param {boolean} forceTowerRedraw If true we force the path pylon model to redraw.
   */
  async function prepareWires(
    pathPoints,
    pathElevationPoints,
    pathColor,
    pathId,
    pathMetadata,
    pylonModel,
    forcePylonRedraw
  ) {
    const showPylons = localSettingsState.localSettings.showPylons;

    // pylonLines is for example:
    // 0: (2)[26.48, 0.00701]
    // 1: (2)[26.48, -0.00701]
    let pylonLines = pylonModel.metadata.lines;

    if (!pylonModel || !pylonLines?.entries()) {
      enqueueSnackbar(t("Technologies.PylonModelError"), {
        variant: "error"
      });
      Sentry.captureMessage("There is a problem with the pylon model.");
    } else {
      // Iterate each connector of the pylon.
      for (let [crossarmConnectorIndex, val] of pylonLines.entries()) {
        // Elevation of the crossarm (that's the elevation of the connector).
        const crossarmConnectorElevation = val[0];

        // Lateral cables displacement (meter to kilometers).
        const crossarmConnectorShift = val[1] / 1000;

        // We call the method to calculate the wire for the connector
        // in this itertion.
        if (showPylons || (!showPylons && crossarmConnectorIndex === 0)) {
          calculateWire(
            pathPoints,
            pathMetadata,
            pathElevationPoints,
            pylonModel,
            crossarmConnectorIndex,
            crossarmConnectorShift,
            crossarmConnectorElevation,
            pathColor,
            pathId,
            forcePylonRedraw
          );
        }
      }
    }
  }

  /**
   * Method to launch the rendering of an overhead section.
   * It gathers the available technologies and calls the method
   * to prepare the wires. If there's no pylon associated to the
   * section technology, it sets a default pylon as model.
   *
   * @param {object} pathScenario Scenario where the path belongs.
   * @param {number} pathId Id of the path.
   * @param {array} pathPoints Points (x,y) forming the path section.
   * @param {array} pathElevationPoints Elevation points for the section.
   * @param {array} sectionMetadata Path metadata corresponding to the current section.
   * @param {string} pathColor Color of the path.
   * @param {object} leadNode First node of the section.
   * @param {bolean} forcePylonRedraw If true we force the path pylon model to redraw.
   *
   */
  async function generateOverhead(
    pathScenario,
    pathId,
    pathPoints,
    pathElevationPoints,
    sectionMetadata,
    pathColor,
    leadNode,
    sectionIdx,
    forcePylonRedraw
  ) {
    let pylonModel;

    // Get the technology for the section.
    const technologyData = availableTechnologies?.find(
      tech => tech.name === leadNode.technology
    );

    // Retrieve the technology 3D model (pylon).
    if (technologyData) {
      const nodeTech = await fetchTechnology(technologyData.id);
      if (nodeTech) {
        pylonModel = nodeTech.model_3d;
      }
    }

    // If no pylon model for the technology, we use local defaut pylon
    // from /public/models/DefaultPylon.json
    if (!pylonModel?.glb_file) {
      enqueueSnackbar(t("Technologies.NoPylon", { sectionIdx: sectionIdx }), {
        variant: "warning"
      });
      const defaultPylon = await axios.get("models/DefaultPylon.json");

      pylonModel = {
        id: 0,
        description: "",
        parameter_type: "",
        value: null,
        unit: "",
        thumbnail: defaultPylon.data.thumbnail,
        glb_file: defaultPylon.data.glb_file,
        metadata: {
          volt: defaultPylon.data.volt,
          lines: defaultPylon.data.lines,
          wires: defaultPylon.data.wires,
          height: defaultPylon.data.height,
          sag_arc_radius: defaultPylon.data.sag_arc_radius,
          minimum_distance_to_ground:
            defaultPylon.data.minimum_distance_to_ground
        }
      };
      // Set path values in context (DEPRECATED?)
      map3DContext.setJSonData(
        pathId,
        pathScenario.id,
        pathPoints,
        pylonModel,
        pathColor
      );

      // Clear cable errors before create again
      map3DContext.optimalPathErrorsClear(pathId);

      // Prepare the wires to render.
      await prepareWires(
        pathPoints,
        pathElevationPoints,
        pathColor,
        pathId,
        sectionMetadata,
        pylonModel,
        forcePylonRedraw
      );
    } else {
      // Set path values in context (DEPRECATED?)
      map3DContext.setJSonData(
        pathId,
        pathScenario.id,
        pathPoints,
        pylonModel,
        pathColor
      );

      // Clear cable errors before create again
      map3DContext.optimalPathErrorsClear(pathId);

      // Prepare the wires to render.
      await prepareWires(
        pathPoints,
        pathElevationPoints,
        pathColor,
        pathId,
        sectionMetadata,
        pylonModel,
        forcePylonRedraw
      );
    }
  }

  /**
   * Method to prepare the rendering of an underground section.
   *
   * @param {number} pathId Id of the path.
   * @param {array} pointsData Path points.
   * @param {string} pathColor Path color.
   */
  async function generateEarthCable(pathId, pointsData, pathColor) {
    // Call the method to create the underground trench entity.
    await createEarthCable(pathId, pointsData, pathColor);
  }

  /**
   * Method to prepare the rendering of a tunnel section.
   *
   * @param {number} pathId Id of the path.
   * @param {array} pointsData Path points.
   * @param {array} elevationPoints Path elevation points.
   * @param {string} pathColor Path color.
   */
  async function generateTunnel(
    pathId,
    pointsData,
    elevationPoints,
    pathColor
  ) {
    // Call the method to create the tunnel entity.
    await createTunnel(pathId, pointsData, elevationPoints, pathColor);
  }

  /**
   * Toggle path visibility of existing paths in the viewer.
   *
   * @param {number} pathId Id of the path.
   * @param {boolean} visible Visibility of the path.
   */
  const togglePathVisibility = (pathId, visible) => {
    // Set internal visiblity
    map3DContext.toggleOptimalVisibilityInternalValue(pathId, visible);

    let pathSource = getDatasourceLayerByName(viewer, optimalPathVectorName);

    toggleEntityVisiblityByProperty(
      viewer,
      pathSource.entities,
      "group",
      pathId,
      visible
    );

    let showPylons = localSettingsState?.localSettings?.showPylons || false;

    // Check if pylons is showed or not
    if (visible && !showPylons) {
      pathSource.entities.suspendEvents();
      let entities = pathSource.entities.values;
      for (const element of entities) {
        // Towers
        if (element.name === towerName) {
          element.model.show = showPylons;
        } else {
          // Wires
          let currentPositions = showPylons
            ? element.internalPosition
            : element.constantHeightPosition;

          let clampToGround = showPylons ? false : true;

          let isOne = element.wireIndex === 0 ? true : false;
          element.show = isOne;
          element.polyline.positions.setValue(currentPositions);
          element.polyline.clampToGround = clampToGround;
        }
      }
      pathSource.entities.resumeEvents();
      viewer.scene.requestRender();
    }
  };

  /**
   * This is the main method to launch the visualization of a path in Cesium.
   * - Look for the path sections by type.
   * - Calculate elevations.
   * - Render transitions.
   * - Render sections by type (O, U, T)
   *
   * @param {object} pathScenario Scenario where the path belongs.
   * @param {boolean} visible Path visibility.
   * @param {object} path Path data.
   * @param {string} pathId Id of the path.
   * @param {string} color Path color.
   * @param {objext} metadata Path metadata.
   */
  async function viewPath(
    pathScenario,
    visible,
    path,
    pathId,
    color,
    metadata
  ) {
    try {
      let pathColor = color;

      if (path === null) {
        return;
      }

      // This happens when this is empty scenario.scenarioconfig.path_color
      if (color === "" || color === undefined) {
        pathColor = defaultColor;
      }

      // Start process to show the path.
      if (visible) {
        // Get path sections (by type).
        const sections = getPathSections(path, metadata);

        // Calculate elevation points for the path.
        let cartoPathPoints = [];
        const pathPoints = path.path.coordinates;
        const len = pathPoints.length;
        for (let i = 0; i < len; i++) {
          // Store the cartographic point of the path to calculate
          // elevations after the loop.
          cartoPathPoints.push(
            Cartographic.fromDegrees(
              ...point(pathPoints[i]).geometry.coordinates,
              new Cartographic()
            )
          );
        }
        const pathElevationPoints = await getCesiumElevatonPoints(
          cartoPathPoints,
          map3DContext.state.terrainAsset
        );

        // Show if is created before and disable recreate again
        // if (map3DContext.state.JSONdata[pathId]) {
        //   togglePathVisibility(pathId, true);
        //   return;
        // }

        // Get sections by TECH.
        let finalTechs = [];
        const techs = getPathTechnologies(path, metadata);

        // Adjust start and end indexes of each technology section
        // based on technology type.
        techs.forEach(tech => {
          const s = sections.find(section => section.idx == tech.sectionIdx);
          let { start, end, ...rest } = tech;
          const techStart = start + s.start;
          const techEnd = end + s.start;
          finalTechs.push({ ...rest, start: techStart, end: techEnd });
        });

        for (let index = 0; index < finalTechs.length; index++) {
          const section = finalTechs[index];

          // Store the lead node for the current section.
          const leadNode = getNode(path, metadata, section.start);

          // Points for the section.
          const pathPoints = [...section.coordinates];
          if (pathPoints.length < 1) {
            enqueueSnackbar(t("error.pathEmpty"), { variant: "error" });
            return;
          }

          const sectionElevationPoints = pathElevationPoints.slice(
            section.start,
            index === finalTechs.length - 1 ? undefined : section.end + 1
          );

          // Find transition points where technology type changes.
          let sectionTransitions = finalTechs.map(section => section.end);
          sectionTransitions = [0, ...sectionTransitions];

          // Render transition models (Where technology type changes).
          sectionTransitions.forEach((transitionIdx, index) => {
            // Get the transition node.
            const transitionNode = getNode(path, metadata, transitionIdx);

            // Find next node to calculate bearing and orientation. If it's the last node
            // we use the node itself.
            const pathLength = path?.path?.coordinates?.length;

            const nextNodeIdx =
              transitionIdx < pathLength - 1
                ? transitionIdx + 1
                : transitionIdx;
            const nextNode = getNode(path, metadata, nextNodeIdx);

            // Render transition model in all transition but first and last (start and end points).
            if (index !== 0 && index !== sectionTransitions.length - 1)
              createTransition(
                pathId,
                transitionNode,
                nextNode,
                pathElevationPoints[transitionNode.idx],
                color,
                0
              );
          });

          // Generate paths based on the section type
          // (Overhead, Underground and Tunnel).
          switch (section.type) {
            case "Overhead":
              generateOverhead(
                pathScenario,
                pathId,
                pathPoints,
                sectionElevationPoints,
                section.metadata,
                pathColor,
                leadNode,
                index,
                true
              );
              break;
            case "Earth Cable":
              //Generate earth cable (underground).
              generateEarthCable(pathId, pathPoints, pathColor);
              break;
            case "Tunnel":
              generateTunnel(
                pathId,
                pathPoints,
                sectionElevationPoints,
                pathColor
              );
              break;
            default:
              break;
          }
        }
      } else {
        // Hide Entities by condition.
        togglePathVisibility(pathId, false);
      }
    } catch (err) {
      console.log(err);
    }
  }

  return {
    showPath: viewPath
  };
};

export default useCesiumPath;
