import React, { useContext } from "react";

// Self context
const DispatchContext = React.createContext();
const StateContext = React.createContext();

/**
 * Initial state for the Comments Context
 */
const initialState = {
  threads: null,
  selectedThreadId: null,
  selectedCommentId: null,
  comments: null,
  newPointCoordinates: null,
  newFeatureCoordinates: null,
  onMap: false,
  newCommentsAvailable: false,
  refreshComments: false,
  refreshThreads: false,
  mode: "project",
  isFetching: false,
  error: null
};

/**
 * Reducer with the actions to update the Comments Context state.
 * @param { object } state
 * @param { object } action
 * @returns
 */
function CommentsReducer(state, action) {
  switch (action.type) {
    case "FETCHING_START": {
      return {
        ...state,
        isFetching: true,
        error: null
      };
    }
    case "THREADS_FETCH_FAIL": {
      return {
        ...state,
        error: action.payload.error,
        threads: null,
        comments: null,
        isFetching: false
      };
    }

    case "COMMENTS_FETCH_FAIL": {
      return {
        ...state,
        error: action.payload.error,
        comments: null,
        isFetching: false
      };
    }

    case "THREADS_FETCH_SUCCESS": {
      return {
        ...state,
        threads: action.payload.threads,
        refreshThreads: false,
        isFetching: false,
        error: null
      };
    }
    case "THREAD_SELECTED_SET": {
      return {
        ...state,
        selectedThreadId: action.payload.selectedThreadId
      };
    }

    case "COMMENT_SELECTED_SET": {
      return {
        ...state,
        selectedCommentId: action.payload.selectedCommentId
      };
    }

    case "THREAD_SELECTED_CLEAR": {
      return {
        ...state,
        selectedThreadId: null
      };
    }
    case "COMMENTS_FETCH_SUCCESS": {
      return {
        ...state,
        comments: action.payload.comments,
        refreshComments: false,
        isFetching: false,
        error: null
      };
    }
    case "COMMENTS_POINT_ADD": {
      return {
        ...state,
        newPointCoordinates: action.payload.newPointCoordinates,
        newFeatureCoordinates: action.payload.newFeatureCoordinates
      };
    }

    case "THREADS_RENDERED_SET": {
      return {
        ...state,
        onMap: action.payload.onMap
      };
    }

    case "COMMENTS_NEW_AVAILABLE_SET": {
      return {
        ...state,
        newCommentsAvailable: action.payload.newCommentsAvailable
      };
    }

    case "ERRORS_RESET": {
      return {
        ...state,
        error: null
      };
    }

    case "MODE_SET": {
      return {
        ...state,
        mode: action.payload.mode
      };
    }

    case "CLEANUP": {
      return {
        threads: null,
        selectedThreadId: null,
        selectedCommentId: null,
        comments: null,
        newPointCoordinates: null,
        newFeatureCoordinates: null,
        onMap: false,
        newCommentsAvailable: false,
        refreshComments: false,
        refreshThreads: false,
        isFetching: false,
        error: null
      };
    }

    case "COMMENTS_REFRESH_SET": {
      return {
        ...state,
        refreshComments: action.payload.refreshComments
      };
    }

    case "THREADS_REFRESH_SET": {
      return {
        ...state,
        refreshThreads: action.payload.refreshThreads
      };
    }

    default: {
      return state;
    }
  }
}

/**
 * Provides all children with the state and the dispatch of the
 * Comments Context.
 *
 * @param { node } children
 * @returns node
 */
export default function CommentsProvider({ children }) {
  const [state, dispatch] = React.useReducer(CommentsReducer, initialState);

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </DispatchContext.Provider>
  );
}
/**
 * Hook to access and manage the state of the Comments Context
 */
const useCommentsContext = () => {
  const commentsState = useContext(StateContext);
  const commentsDispatch = useContext(DispatchContext);

  return [commentsState, commentsDispatch];
};

export { CommentsProvider, useCommentsContext };
